export default {
  'pages.layouts.userLayout.title': 'Ant Designは、西湖区で最も影響力のあるWebデザイン仕様です。',
  'pages.login.accountLogin.tab': 'アカウントログイン',
  'pages.login.accountLogin.errorMessage':
    'ユーザー名/パスワードが正しくありません（admin/ant.design)',
  'pages.login.username.placeholder': 'ユーザー名：adminまたはuser',
  'pages.login.username.required': 'ユーザー名を入力してください！',
  'pages.login.password.placeholder': 'パスワード：ant.design',
  'pages.login.password.required': 'パスワードを入力してください！',
  'pages.login.phoneLogin.tab': '電話ログイン',
  'pages.login.phoneLogin.errorMessage': '検証コードエラー',
  'pages.login.phoneNumber.placeholder': '電話番号',
  'pages.login.phoneNumber.required': '電話番号を入力してください！',
  'pages.login.phoneNumber.invalid': '電話番号が無効です！',
  'pages.login.captcha.placeholder': '確認コード',
  'pages.login.captcha.required': '確認コードを入力してください！',
  'pages.login.phoneLogin.getVerificationCode': '確認コードを取得',
  'pages.getCaptchaSecondText': '秒',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'パスワードをお忘れですか？',
  'pages.login.submit': 'ログイン',
  'pages.login.loginWith': 'その他のログイン方法：',
  'pages.login.registerAccount': 'アカウント登録',
  'pages.welcome.advancedComponent': '高度なコンポーネント',
  'pages.welcome.link': 'ようこそ',
  'pages.welcome.advancedLayout': '高度なレイアウト',
  'pages.welcome.alertMessage': 'より高速で強力な頑丈なコンポーネントがリリースされました。',
  'pages.admin.subPage.title': 'このページは管理者のみが表示できます',
  'pages.admin.subPage.alertMessage':
    'Umi uiがリリースされました。npm run uiを使用して体験してください。',
  'pages.searchTable.createForm.newRule': '新しいルール',
  'pages.searchTable.updateForm.ruleConfig': 'ルール構成',
  'pages.searchTable.updateForm.basicConfig': '基本情報',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'ルール名',
  'pages.searchTable.updateForm.ruleName.nameRules': 'ルール名を入力してください！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'ルールの説明',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '5文字以上入力してください',
  'pages.searchTable.updateForm.ruleDesc.descRules': '5文字以上のルールの説明を入力してください！',
  'pages.searchTable.updateForm.ruleProps.title': 'プロパティの構成',
  'pages.searchTable.updateForm.object': '監視対象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'ルールテンプレート',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'ルールタイプ',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'スケジュール期間の設定',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '開始時間',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '開始時間を選択してください！',
  'pages.searchTable.titleDesc': '説明',
  'pages.searchTable.ruleName': 'ルール名が必要です',
  'pages.searchTable.titleCallNo': 'サービスコール数',
  'pages.searchTable.titleStatus': 'ステータス',
  'pages.searchTable.nameStatus.default': 'デフォルト',
  'pages.searchTable.nameStatus.running': '起動中',
  'pages.searchTable.nameStatus.online': 'オンライン',
  'pages.searchTable.nameStatus.abnormal': '異常',
  'pages.searchTable.titleUpdatedAt': '最終スケジュール',
  'pages.searchTable.exception': '例外の理由を入力してください！',
  'pages.searchTable.titleOption': 'オプション',
  'pages.searchTable.config': '構成',
  'pages.searchTable.subscribeAlert': 'アラートを購読する',
  'pages.searchTable.title': 'お問い合わせフォーム',
  'pages.searchTable.new': '新しい',
  'pages.searchTable.chosen': '選んだ項目',
  'pages.searchTable.item': '項目',
  'pages.searchTable.totalServiceCalls': 'サービスコールの総数',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': 'バッチ削除',
  'pages.searchTable.batchApproval': 'バッチ承認',
};
