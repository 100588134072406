export default {
  'pages.layouts.userLayout.title': 'طراحی مورچه تأثیرگذارترین مشخصات طراحی وب در منطقه Xihu است',
  'pages.login.accountLogin.tab': 'ورود به حساب کاربری',
  'pages.login.accountLogin.errorMessage': 'نام کاربری / رمزعبور نادرست （مدیر / ant.design)',
  'pages.login.username.placeholder': 'نام کاربری: مدیر یا کاربر',
  'pages.login.username.required': 'لطفا نام کاربری خود را وارد کنید!',
  'pages.login.password.placeholder': 'رمز عبور: ant.design',
  'pages.login.password.required': 'لطفاً رمز ورود خود را وارد کنید!',
  'pages.login.phoneLogin.tab': 'ورود به سیستم تلفن',
  'pages.login.phoneLogin.errorMessage': 'خطای کد تأیید',
  'pages.login.phoneNumber.placeholder': 'شماره تلفن',
  'pages.login.phoneNumber.required': 'لطفاً شماره تلفن خود را وارد کنید!',
  'pages.login.phoneNumber.invalid': 'شماره تلفن نامعتبر است!',
  'pages.login.captcha.placeholder': 'کد تایید',
  'pages.login.captcha.required': 'لطفا کد تأیید را وارد کنید!',
  'pages.login.phoneLogin.getVerificationCode': 'دریافت کد',
  'pages.getCaptchaSecondText': 'ثانیه',
  'pages.login.rememberMe': 'مرا به خاطر بسپار',
  'pages.login.forgotPassword': 'رمز عبور را فراموش کرده اید ?',
  'pages.login.submit': 'ارسال',
  'pages.login.loginWith': 'وارد شوید با :',
  'pages.login.registerAccount': 'ثبت نام',
  'pages.welcome.advancedComponent': 'مولفه پیشرفته',
  'pages.welcome.link': 'خوش آمدید',
  'pages.welcome.advancedLayout': 'چیدمان پیشرفته',
  'pages.welcome.alertMessage': 'اجزای سنگین تر سریعتر و قوی تر آزاد شده اند.',
  'pages.admin.subPage.title': 'این صفحه فقط توسط مدیر قابل مشاهده است',
  'pages.admin.subPage.alertMessage':
    'رابط کاربری Umi اکنون منتشر شده است ، برای شروع تجربه استفاده از npm run ui خوش آمدید.',
  'pages.searchTable.createForm.newRule': 'قانون جدید',
  'pages.searchTable.updateForm.ruleConfig': 'پیکربندی قانون',
  'pages.searchTable.updateForm.basicConfig': 'اطلاعات اولیه',
  'pages.searchTable.updateForm.ruleName.nameLabel': ' نام قانون',
  'pages.searchTable.updateForm.ruleName.nameRules': 'لطفاً نام قانون را وارد کنید!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'شرح قانون',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'لطفاً حداقل پنج حرف وارد کنید',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'لطفاً حداقل یک قانون حاوی پنج کاراکتر شرح دهید!',
  'pages.searchTable.updateForm.ruleProps.title': 'پیکربندی خصوصیات',
  'pages.searchTable.updateForm.object': 'نظارت بر شی',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'الگوی قانون',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'نوع قانون',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'تنظیم دوره زمان بندی',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'زمان شروع',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'لطفاً زمان شروع را انتخاب کنید!',
  'pages.searchTable.titleDesc': 'شرح',
  'pages.searchTable.ruleName': 'نام قانون لازم است',
  'pages.searchTable.titleCallNo': 'تعداد تماس های خدماتی',
  'pages.searchTable.titleStatus': 'وضعیت',
  'pages.searchTable.nameStatus.default': 'پیش فرض',
  'pages.searchTable.nameStatus.running': 'در حال دویدن',
  'pages.searchTable.nameStatus.online': 'برخط',
  'pages.searchTable.nameStatus.abnormal': 'غیرطبیعی',
  'pages.searchTable.titleUpdatedAt': 'آخرین برنامه ریزی در',
  'pages.searchTable.exception': 'لطفا دلیل استثنا را وارد کنید!',
  'pages.searchTable.titleOption': 'گزینه',
  'pages.searchTable.config': 'پیکربندی',
  'pages.searchTable.subscribeAlert': 'مشترک شدن در هشدارها',
  'pages.searchTable.title': 'فرم درخواست',
  'pages.searchTable.new': 'جدید',
  'pages.searchTable.chosen': 'انتخاب شده',
  'pages.searchTable.item': 'مورد',
  'pages.searchTable.totalServiceCalls': 'تعداد کل تماس های خدماتی',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'حذف دسته ای',
  'pages.searchTable.batchApproval': 'تصویب دسته ای',
};
