export default {
  'pages.layouts.userLayout.title':
    'Ant Design adalah spesifikasi desain Web yang paling berpengaruh di Kabupaten Xihu',
  'pages.login.accountLogin.tab': 'Login dengan akun',
  'pages.login.accountLogin.errorMessage': 'Nama pengguna dan kata sandi salah（admin/ant.design)',
  'pages.login.username.placeholder': 'nama pengguna: admin atau user',
  'pages.login.username.required': 'Nama pengguna harus diisi!',
  'pages.login.password.placeholder': 'kata sandi: ant.design',
  'pages.login.password.required': 'Kata sandi harus diisi!',
  'pages.login.phoneLogin.tab': 'Login dengan ponsel',
  'pages.login.phoneLogin.errorMessage': 'Kesalahan kode verifikasi',
  'pages.login.phoneNumber.placeholder': 'masukkan nomor telepon',
  'pages.login.phoneNumber.required': 'Nomor ponsel harus diisi!',
  'pages.login.phoneNumber.invalid': 'Nomor ponsel tidak valid!',
  'pages.login.captcha.placeholder': 'kode verifikasi',
  'pages.login.captcha.required': 'Kode verifikasi diperlukan!',
  'pages.login.phoneLogin.getVerificationCode': 'Dapatkan kode',
  'pages.getCaptchaSecondText': 'detik tersisa',
  'pages.login.rememberMe': 'Ingat saya',
  'pages.login.forgotPassword': 'Lupa Kata Sandi?',
  'pages.login.submit': 'Masuk',
  'pages.login.loginWith': 'Masuk dengan :',
  'pages.login.registerAccount': 'Daftar Akun',
  'pages.welcome.advancedComponent': 'Formulir Lanjutan',
  'pages.welcome.link': 'Selamat datang',
  'pages.welcome.advancedLayout': 'Tata letak Lanjutan',
  'pages.welcome.alertMessage':
    'Komponen heavy-duty yang lebih cepat dan lebih kuat telah dirilis.',
  'pages.admin.subPage.title': 'Halaman ini hanya dapat dilihat oleh admin',
  'pages.admin.subPage.alertMessage':
    'umi ui telah dirilis, silahkan gunakan npm run ui untuk memulai pengalaman.',
  'pages.searchTable.createForm.newRule': 'Aturan baru',
  'pages.searchTable.updateForm.ruleConfig': 'Konfigurasi aturan',
  'pages.searchTable.updateForm.basicConfig': 'Informasi dasar',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Nama aturan',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Harap masukkan nama aturan!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Deskripsi aturan',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder':
    'Harap masukkan setidaknya lima karakter',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Harap masukkan deskripsi aturan setidaknya lima karakter!',
  'pages.searchTable.updateForm.ruleProps.title': 'Properti aturan',
  'pages.searchTable.updateForm.object': 'Objek pemantauan',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Template aturan',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Jenis aturan',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Periode penjadwalan',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Waktu mulai',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Pilih waktu mulai!',
  'pages.searchTable.titleDesc': 'deskripsi',
  'pages.searchTable.ruleName': 'Nama aturan wajib diisi',
  'pages.searchTable.titleCallNo': 'Jumlah panggilan',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'menyala',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Waktu terjadwal',
  'pages.searchTable.exception': 'Harap masukkan alasan pengecualian!',
  'pages.searchTable.titleOption': 'Pengoperasian',
  'pages.searchTable.config': 'Konfigurasi',
  'pages.searchTable.subscribeAlert': 'Berlangganan notifikasi',
  'pages.searchTable.title': 'Formulir pertanyaan',
  'pages.searchTable.new': 'Baru',
  'pages.searchTable.chosen': 'Terpilih',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Jumlah total panggilan layanan',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'Penghapusan batch',
  'pages.searchTable.batchApproval': 'Persetujuan batch',
};
